import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import {
	FaChartLine,
	FaProjectDiagram,
	FaExchangeAlt, // or FaSyncAlt, if you prefer
	FaDatabase, // or FaDatabase
	FaClock,
	FaCalendarAlt,
	FaFilter // or FaSearchPlus
} from "react-icons/fa";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Import videos
import StripTwoColumnIconSmall from "src/components/generic/StripTwoColumnIconSmall/StripTwoColumnIconSmall";
import OutboundLink from "src/components/mdx/OutboundLink/OutboundLink";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Traffic Change Report",
	subTitle: (
		<>
			<p>
				This report will help you understand how your traffic has
				changed.
			</p>
			<OutboundLink
				href="https://lookerstudio.google.com/reporting/112a6887-1b38-41f7-ab08-c1faa1bd8030/"
				className={`button buttonSecondary`}
			>
				Explore anonymised report
			</OutboundLink>
		</>
	)
};

const alternatingContent = [
	{
		subTitle: "Video Walkthrough",
		content: (
			<React.Fragment>
				<p>Sometimes it&apos;s easier to watch a video.</p>
				<p>
					If you&apos;d like a short walkthrough of what this report
					can do we&apos;ve got one here.
				</p>
			</React.Fragment>
		),
		secondContent: (
			<>
				<div className={`videoHolder`}>
					<iframe
						src="https://player.vimeo.com/video/909205612?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						title="piped out intro 3"
					></iframe>
				</div>
				<script src="https://player.vimeo.com/api/player.js%22%3E" />
			</>
		)
	}
];

const dashboardStrengths = [
	{
		title: "Understand where your traffic has changed quickly",
		content: (
			<React.Fragment>
				<p>
					We pull out all of your website segments and show you how
					traffic has changed.
				</p>
				<p>
					You can quickly drill down through them to see where things
					change.
				</p>
			</React.Fragment>
		),
		icon: <FaChartLine />
	},
	{
		title: "Dig out the segments that matter",
		content: (
			<React.Fragment>
				<p>
					We breakdown the change so you can see every segment as
					percentage of the total.
				</p>
				<p>
					No more sorting by most changed and seeing a 5000% decrease
					on a T&C&apos;s page that lost 10 sessions.
				</p>
			</React.Fragment>
		),
		icon: <FaFilter />
	},
	{
		title: "Switch between metrics easily",
		content: (
			<React.Fragment>
				<p>So your sessions dropped, but how about revenue?</p>
				<p>
					Our entire dashboard can switch between any metrics
					you&apos;d like with the click of a button.
				</p>
			</React.Fragment>
		),
		icon: <FaDatabase />
	},
	{
		title: "Dig into Search Console and analytics",
		content: (
			<React.Fragment>
				<p>
					We join Search Console with analytics so if you&apos;re
					digging into an organic traffic drop you can move from pages
					and sessions to keywords seamlessly
				</p>
			</React.Fragment>
		),
		icon: <FaProjectDiagram />
	},
	{
		title: "Automatic updates",
		content: (
			<React.Fragment>
				<p>
					Like all our dashboards the data is updated automatically
					every day.
				</p>
			</React.Fragment>
		),
		icon: <FaClock />
	},
	{
		title: "Year on Year comparision",
		content: (
			<React.Fragment>
				<p>
					We combine YoY and PoP data so you can see if the change is
					due to seasonality or an actual cause.
				</p>
			</React.Fragment>
		),
		icon: <FaCalendarAlt />
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Set images

	const title = "Diagnose Traffic Drops Dashboard";
	const description =
		"If you spend a lot of time trying to understand how and why your traffic is changing, take a look at our dashboard template. We'll let you dig in by segments, help you discover seasonlity and easily switch between sessions, revenue and conversions so you can see if the changes are happening everywhere.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>

			<section>
				{/* <StripTitle title="How does analysing data work?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
					noBotMargin={true}
				/>
			</section>
			<StripTitle title="Why use our dashboard?" />
			<StripTwoColumnIconSmall
				contentList={dashboardStrengths}
				backgroundGrey={false}
			/>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query TrafficChangeData {
		# Get us the main alternating images
		alternatingImg1: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/save_query_results.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 681, maxHeight: 632) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/query_builder.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 916, maxHeight: 705) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg3: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/business_logic.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1051, maxHeight: 866) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
